import * as React from 'react';
import './main.scss';
import Layout from '../common/Layout';
import PageHeader from '../components/PageHeader';
import AboutUs from '../components/AboutUs';
import OurServices from '../components/OurServices';
import OurTeam from '../components/OurTeam';
import JoinUs from '../components/JoinUs';
import Footer from '../common/Footer';

export default function Index() {
  return (
    <Layout>
      <PageHeader></PageHeader>
      <AboutUs></AboutUs>
      <OurServices></OurServices>
      <OurTeam></OurTeam>
      <JoinUs></JoinUs>
      <Footer></Footer>
    </Layout>
  );
}
