import React from "react";
import Title from "../../common/Title";
import TeamCard from "../TeamCard";

export default function OurTeam() {
  return (
    <>
      <section id="our-team" className="our-team">
        <Title>Nossa equipe</Title>
        <div className="team">
          <TeamCard
            name="Alex Sant'Anna"
            imageWebp="images/team/alex.webp"
            imageJpeg="images/team/alex.jpg"
            github="https://github.com/alexRicc2"
            linkedin="https://www.linkedin.com/in/alex-ricardo-rodrigues-sant-anna"
          ></TeamCard>
          <TeamCard
            name="Arthur Uezu"
            imageWebp="images/team/arthur.webp"
            imageJpeg="images/team/arthur.jpg"
            github="https://github.com/ArthurUezu"
            linkedin="https://www.linkedin.com/in/arthur-uezu/"
          ></TeamCard>
          <TeamCard
            name="Diego Lopes"
            imageWebp="images/team/diego.webp"
            imageJpeg="images/team/diego.jpg"
            github="https://github.com/deigo-lps"
            linkedin="https://linkedin.com/in/diego-lopes-8644a5208"
          ></TeamCard>
          <TeamCard
            name="Gabriel Scarano"
            imageWebp="images/team/gabriel.webp"
            imageJpeg="images/team/gabriel.jpg"
            github="https://github.com/gabriel-scarano"
            linkedin="https://www.linkedin.com/in/gabriel-scarano-1709811a2"
          ></TeamCard>
          <TeamCard
            name="Jardel Brandão"
            imageWebp="images/team/jardel.webp"
            imageJpeg="images/team/jardel.jpg"
            github="https://github.com/minisuricato"
            linkedin="https://linkedin.com/in/jardel-brand%C3%A3o-2266b71b2/"
          ></TeamCard>
          <TeamCard
            name="Jean Achour"
            imageWebp="images/team/jean.webp"
            imageJpeg="images/team/jean.jpg"
            github="https://github.com/SeawardSalmon6"
            linkedin="https://www.linkedin.com/in/jean-achour-7023b620b/"
          ></TeamCard>
          <TeamCard
            name="Júlia Rodrigues"
            imageWebp="images/team/juliar.webp"
            imageJpeg="images/team/juliar.jpg"
            github="https://github.com/juliarmn"
          ></TeamCard>
          <TeamCard
            name="Lucas Emanuel"
            imageWebp="images/team/lucas.webp"
            imageJpeg="images/team/lucas.jpg"
            github="https://github.com/LucasGenova"
          ></TeamCard>
          <TeamCard
            name="Mateus Rosolem"
            imageWebp="images/team/mateusr.webp"
            imageJpeg="images/team/mateusr.jpg"
            github="https://github.com/MateusRosolem"
            linkedin="https://www.linkedin.com/in/mateus-rosolem-8a0a10b5/"
          ></TeamCard>
          <TeamCard
            name="Otávio Augusto"
            imageWebp="images/team/otavio.webp"
            imageJpeg="images/team/otavio.jpg"
            github="https://github.com/T4vexx"
            linkedin="https://www.linkedin.com/in/otavio-augusto-teixeira-0012"
          ></TeamCard>
          <TeamCard
            name="Sabrina Oliveira"
            imageWebp="images/team/sabrina.webp"
            imageJpeg="images/team/sabrina.jpg"
            github="https://github.com/SabrinaGuizilini"
            linkedin="https://www.linkedin.com/in/sabrina-guizilini-29b736226"
          ></TeamCard>
          <TeamCard
            name="Sarah Ferreira"
            imageWebp="images/team/sarah.webp"
            imageJpeg="images/team/sarah.jpg"
            github="https://github.com/SarahOFerreira"
            linkedin="https://www.linkedin.com/in/sarah-ferreira-982949215/"
          ></TeamCard>
        </div>
      </section>
    </>
  );
}
