import React from 'react';
import Title from '../../common/Title';
export default function AboutUs() {
  return (
    <div id="about-us" className="about-us">
      <div className="disclaimer">
        <Title>Quem somos</Title>
        <p>
          Formada em 1993 por alunos do curso de Bacharelado em
          <a href="https://www.ibilce.unesp.br" className="link" target="_blank" rel="noreferrer"> Ciência
          da Computação - IBILCE/UNESP S. J. Rio
          Preto</a>, somos uma empresa júnior da área de Tecnologia
          da Informação atualmente focada em Desenvolvimento Web.
        </p>
      </div>
      <div className="image">
        <img src="images/computer.svg" alt="Quem somos" />
      </div>
    </div>
  )
}