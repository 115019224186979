import React, {useEffect} from 'react';
import { Helmet } from "react-helmet"
import TagManager from 'react-gtm-module';

const tagManagerArgs = {
  gtmId: 'GTM-KVNWMGH',
};

export default function Layout({children}) {
  useEffect(function() {
    TagManager.initialize(tagManagerArgs);
  }, []);

  return (
    <main>
      <Helmet>
        <meta charSet="utf-8" />
        <title>eccjr - Empresa Júnior de Ciência da Computação</title>
        <meta name="description" content="Crie seu site responsivo ou sistema web com a empresa júnior da UNESP de São José do Rio Preto SP" />
        <link rel="canonical" href="https://eccjr.com.br/" />
      </Helmet>
      {children}
    </main>
  )
}
