import React from 'react';
export default function CopyText(props) {
  return (
    <div class="tooltip">
      <p className={props.classe} onClick={() => {
        navigator.clipboard.writeText(props.copy);
        var tooltip = document.getElementById("myTooltip");
        tooltip.innerHTML = "Copiado.";
      }}>
        <span class="tooltiptext" id="myTooltip">Clique para copiar.</span>
        {props.children}</p>
    </div>
  )
}