import React from 'react';
import Navbar from '../../common/Navbar'
import Title from '../../common/Title'
import Subtitle from '../../common/Subtitle'
import Button from '../../common/Button'
export default function PageHeader() {
  return (
    <header>
      <picture>
        <source srcSet="images/background.webp" media="(min-width: 998px)"
          type="image/webp" />
        <source srcSet="images/background.jpg" media="(min-width: 998px)"
          type="image/jpeg" />
        <source srcSet="images/background-mobile.webp"
          media="(max-width: 997px)" type="image/webp" />
        <source srcSet="images/background-mobile.jpg"
          media="(max-width: 997px)" type="image/jpeg" />
        <img src="images/background.jpg" className="background-img" alt="background" />
      </picture>
      <div className="overlay"></div>

      <Navbar></Navbar>

      <div className="header-text">
        <Title>Tradição e qualidade</Title>
        <Subtitle>Faça seu site com a empresa júnior de tecnologia mais<br />conceituada da região de São José do Rio Preto.</Subtitle>
        <Button link="our-services">Nossos serviços</Button>
      </div>
    </header>
  )
}