import React from 'react';
import Title from '../../common/Title';
import Subtitle from '../../common/Subtitle';
export default function OurServices() {
  return (
    <section id="our-services" className="our-services">
      <Title>Nossos serviços</Title>
      <div className="services">
        <div className="card">
          <img src="images/services/desktop.svg" alt="Páginas web" />
          <Subtitle>Páginas web</Subtitle>
          <p>Promova sua empresa e serviços com um novo site responsivo e
            seguro!</p>
        </div>
        <div className="card">
          <img src="images/services/cart.svg" alt="Lojas virtuais" />
          <Subtitle>Lojas virtuais</Subtitle>
          <p>Venda pela Internet com uma loja virtual voltada para o seu
            público alvo!</p>
        </div>
        <div className="card">
          <img src="images/services/user.svg" alt="Sistemas web" />
          <Subtitle>Sistemas web</Subtitle>
          <p>Tenha mais controle da sua empresa com nosso sistema web
            personalizado!</p>
        </div>
        <div className="card">
          <img src="images/services/server.svg" alt="Hospedagem" />
          <Subtitle>Hospedagem</Subtitle>
          <p>Mantemos o seu site no ar 24h por dia de forma segura e
            confiável!</p>
        </div>
      </div>
    </section>
  )
}